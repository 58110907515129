<template>
    <footer
        class="content-footer footer bg-footer-theme"
        :class="['lnb-' + findLayoutClass()]"
    >
        <div
            class="footer-container d-flex align-items-center justify-content-between py-2 px-3 flex-md-row flex-column rounded-0 border-0 shadow-none border-top"
        >
            <div>
                <div v-if="!($store.getters['menu/current'] && $store.getters['menu/shortcuts'].length>=2)" class="fw-medium text-primary">{{ $gpApp.name() }}</div>
                <div v-if="$store.getters['menu/current'] && $store.getters['menu/shortcuts'].length>=2" class="fw-medium text-primary">
                    <i class="me-1" :class="[($store.getters['menu/current'].icon_type==='tabler'?'ti ti-':'bi bi-') + $store.getters['menu/current'].icon_class]" ></i>{{ $store.getters['menu/current'].name }}
                </div>
            </div>
            <div v-if="!$gpUtils.isMobileBrowser()">{{ copyright() }}</div>
        </div>
    </footer>
</template>

<script>
import {layoutHelper} from "../../utils/helpers/layoutHelper.js";

export default {
    name: "NavFooter",
    methods : {
        findLayout() {
            return layoutHelper.findLayout(this.$route.name);
        },
        findLayoutClass() {
            const layout = this.findLayout();
            return (layout==='vertical-blank' ? 'vertical' : (layout==='horizontal-blank' ? 'horizontal' : layout));
        },
        copyright() {
            const d = new Date();
            return '©' + (d.getFullYear()===2024 ? '' : '2024-' ) + d.getFullYear().toString();
        }
    }
}
</script>

<style scoped>

</style>
