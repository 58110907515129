export const appHelper = {
    environment: {
        development: (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'),
        guestEmbedded : !(import.meta.env.VITE_LOGO_LOGIN==='true')
    },
    signIn: {
        google: {
            clientID: import.meta.env.VITE_GOOGLE_CLIENTID,
            recaptcha: import.meta.env.VITE_GOOGLE_RECAPTCHA
        },
        microsoft: {
            clientID: import.meta.env.VITE_MICROSOFT_CLIENTID
        }
    },
    pathApp : {
        home: '/app',
        login: '/app/login',
        adminApp: '/adminapp',
        taskApp: '/taskapp',
        amsApp: '/amsapp',
        hrApp: '/hrapp',
        tmsApp: '/tmsapp',
        clientApp: '/capp',
        supplierApp: '/sapp',
        agencyApp: '/agapp'
    }
};

appHelper.subscriberApp = {
    listSpaApp() {
        const collector = [];
        let pathSplit;

        pathSplit = appHelper.pathApp.adminApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);
        pathSplit = appHelper.pathApp.taskApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);
        pathSplit = appHelper.pathApp.amsApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);
        pathSplit = appHelper.pathApp.hrApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);
        pathSplit = appHelper.pathApp.tmsApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);
        pathSplit = appHelper.pathApp.clientApp.split('/').filter(value => value !== "");
        collector.push(pathSplit[pathSplit.length-1]);

        return collector;
    },
    isAppSlug() {
        return import.meta.env.VITE_APP_SUBSCRIBER === 'slug';
    },
    isAppDomain() {
        return import.meta.env.VITE_APP_SUBSCRIBER === 'domain';
    },
    isAppSubscriber() {
        return appHelper.subscriberApp.isAppSlug() || appHelper.subscriberApp.isAppDomain();
    },
    appKey() {
        if(appHelper.subscriberApp.isAppSlug()) {
            const pathArray = window.location.pathname.split('/').filter(value => value !== "");
            if (pathArray.length) {
                if(!appHelper.subscriberApp.listSpaApp().includes(pathArray[0])) {
                    return pathArray[0];
                }
            }
        }
        return '';
    },
    getModularApp(stringPath) {
        if(appHelper.subscriberApp.isAppSlug()) {
            const pathArray = stringPath.split('/').filter(value => value !== "");
            const appKey = appHelper.subscriberApp.appKey();
            if (pathArray.length) {
                if(pathArray[0]===appKey) {
                    return stringPath;
                } else {
                    return (appKey ? '/' + appKey : '') + stringPath;
                }
            }
        }
        return stringPath;
    },
    getSpaApp(stringPath) {
        if(appHelper.subscriberApp.isAppSlug()) {
            const appKey = appHelper.subscriberApp.appKey();
            const pathArray = stringPath.split('/').filter(value => value !== "");
            if (pathArray.length) {
                if(appKey==='adminapp' && pathArray[0]===appKey) {
                    return stringPath;
                } else {
                    return (appKey ? '/' + appKey : '') + stringPath;
                }
            }
        }
        return stringPath;
    },
    baseSpaAppUrl(stringPath) {
        let pathApp;
        const pathArray = window.location.pathname.split('/').filter(value => value !== "");
        const stringArray = stringPath.split('/').filter(value => value !== "");

        if(appHelper.subscriberApp.isAppSlug()) {
            const appKey = appHelper.subscriberApp.appKey();
            if(appKey && appKey!=='app') {
                pathApp = pathArray[1];
                if (appHelper.subscriberApp.listSpaApp().includes(pathApp) && pathApp!==stringArray[0]) {
                    return '/' + pathApp + '/' + stringArray.join('/');
                }
            }
            return '/' + stringArray.join('/');
        } 

        pathApp = pathArray[0];
        if (appHelper.subscriberApp.listSpaApp().includes(pathApp) && pathApp!==stringArray[0]) {
            return '/' + pathApp + '/' + stringArray.join('/');
        }

        return stringPath;
    },
    getRequestUrl(stringPath) {
        if(appHelper.subscriberApp.isAppSlug()) {
            const appKey = appHelper.subscriberApp.appKey();
            let pathArray = stringPath.split('/').filter(value => value !== "");
            if(appKey && pathArray.length) {
                if(pathArray[0]===appKey) {
                    pathArray.shift();
                    return '/' + pathArray.join('/');
                }
            }
        }
        return stringPath;
    },
    isAdminApp() {
        const pathArray = window.location.pathname.split('/').filter(value => value !== "");
        if (pathArray.length) {
            if (pathArray.length>=2) {
                if(pathArray[0]==='adminapp' || pathArray[1]==='adminapp') {
                    return true;
                }
            } else if(pathArray[0]==='adminapp') {
                return true;
            }
        }
        return false;
    }
}

appHelper.path = {
    home: appHelper.subscriberApp.getModularApp(appHelper.pathApp.home),
    login: appHelper.subscriberApp.getModularApp(appHelper.pathApp.login),
    adminApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.adminApp),
    taskApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.taskApp),
    amsApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.amsApp),
    hrApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.hrApp),
    tmsApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.tmsApp),
    clientApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.clientApp),
    supplierApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.supplierApp),
    agencyApp: appHelper.subscriberApp.getSpaApp(appHelper.pathApp.agencyApp)
};

appHelper.url = {
    auth: (withoutSubscriber = false) => {
        const appKey = appHelper.subscriberApp.appKey();
        return window.location.origin + (withoutSubscriber ? '': (appKey && appKey!=='app' ? ('/' + appKey) : ''));
    },
    api: () => {
        const appKey = appHelper.subscriberApp.appKey();
        return window.location.origin + '/api' + (appKey && appKey!=='app' ? ('/' + appKey) : '') ;
    },
    app: () => {
        return window.location.origin + appHelper.path.home;
    },
    taskApp: () => {
        return window.location.origin + appHelper.path.taskApp;
    },
    adminApp: () => {
        return window.location.origin + appHelper.path.adminApp;
    },
    amsApp: () => {
        return window.location.origin + appHelper.path.amsApp;
    },
    hrApp: () => {
        return window.location.origin + appHelper.path.hrApp;
    },
    root: () => {
        return window.location.origin;
    }
};

