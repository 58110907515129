import {stringHelper} from "../helpers/stringHelper.js";
import {userHelper} from "../helpers/userHelper.js";
import {elementHelper} from "../helpers/elementHelper.js";
import {utilHelper} from "../helpers/utilHelper.js";
import {formatHelper} from "../helpers/formatHelper.js";
import {dateHelper} from "../helpers/dateHelper.js";

export default {
    install: (app, options) => {
        app.config.globalProperties.$gpUtils = {
            uniqueId () {
                return utilHelper.getUniqueId();
            },
            isMobileBrowser () {
                return utilHelper.isMobileBrowser();
            },
            string : {
                splitWhitespaceToArray(str) {
                    return stringHelper.splitWhitespaceToArray(str);
                },
                aliasName(str) {
                    return userHelper.getAlias(str);
                }
            },
            color : {
                random ( forAvatar = false) {
                    const bgColor = elementHelper.getRandomColor();
                    const fontColor = elementHelper.getRandomFontColor(bgColor);

                    if(forAvatar) {
                        return 'background-color:' + bgColor + ';color:' + fontColor;
                    }

                    return {
                        backgroundColor : bgColor,
                        fontColor : fontColor
                    }
                }
            },
            format : {
                formatNumber(value) {
                    let _value = value;
                    if(typeof value !== 'number') {
                        _value = parseFloat(value);
                    }
                    return formatHelper.formatNumber(_value);
                },
                date(dateString) {
                    if(dateString) {
                        return dateHelper.date.default.display.shortFormat(dateString);
                    } else {
                        return '';
                    }
                },
                datetime(dateTimeString) {
                    if(dateTimeString) {
                        return dateHelper.dateTime.default.display.shortFormat(dateTimeString);
                    } else {
                        return '';
                    }
                }
            }
        }
    }
}
